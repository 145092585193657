import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000, // 请求超时时间
  withCredentials: false
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.log(err,'err')
  if (error.response) {
    console.log(error.response,'error.response')
    const data = error.response.data
    // 从 localstorage 获取 token
    if (error.response.status === 400) {
      notification.error({
        message: 'Forbidden',
        description: data.msg
      })
    }
    if (error.response.status === 401) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = ''
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Access-Token'] = token
  }
  return config
}, errorHandler)

// response interceptor
// request.interceptors.response.use((response) => {
//   const token = storage.get(ACCESS_TOKEN)
//   if (response.data.status === 400) {
//     notification.error({
//       message: 'Forbidden',
//       description: response.data.msg
//     })
//   }
//   if (response.data.status === 401) {
//     notification.error({
//       message: 'Unauthorized',
//       description: response.data.msg
//     })
//     if (token) {
//       store.dispatch('Logout').then(() => {
//         setTimeout(() => {
//           window.location.reload()
//         }, 1500)
//       })
//     }
//   }
//   return response.data
// }, errorHandler)

request.interceptors.response.use(
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 0) {
      notification.error({
        message: 'Forbidden',
        description: response.data.msg
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.status === 401) {
        notification.error({
          message: 'Unauthorized',
          description: response.data.msg
        })
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    notification.error({
      message: 'Forbidden',
      description: error.msg
    })
    return Promise.reject(error)
  }
)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
