<template>
  <div class="line">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.line{
  width: 0.64rem;
  height: 0.26rem;
  position: relative;
  span{
    display: inline-block;
    background: #F7C974;
    &:nth-child(1),&:nth-child(5){
      width: 0.04rem;
      height: 0.04rem;
      border-radius: 50%;
    }
    &:nth-child(2),&:nth-child(4){
      width: 0.32rem;
      height: 0.01rem;
    }
    &:nth-child(1){
      position: absolute;
      left: -0.04rem;
      top: -0.02rem;
    }
    &:nth-child(2){
      position: absolute;
      left: 0;
      top: 0;
    }
    &:nth-child(3){
      width: 0.01rem;
      height: 0.26rem;
      position: absolute;
      left: 50%;
      top: 0;
    }
    &:nth-child(4){
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &:nth-child(5){
      position: absolute;
      bottom: -0.02rem;
      right: -0.04rem;
    }
  }
}
</style>