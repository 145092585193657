<template>
  <div class="bloodPressure" id="bloodSugar"></div>
</template>

<script>
export default {
  data(){
    return {
      bloodSugarTop: [],
      bloodSugarBot: [],
      bloodSugarTime: [],
      bloodSugar: null
    }
  },
  methods:{
    initData(data){
      console.log(data)
      this.bloodSugarTop = data.top
      this.bloodSugarBot = data.bot
      this.bloodSugarTime = data.time
      this.initEcharts()
    },
    initEcharts(){
       this.bloodSugar = this.$echarts.init(
        document.getElementById("bloodSugar")
      );
      // console.log(this.bloodSugarBot,'bloodSugarBot')
      var start = this.bloodSugarTime.length - 6
      var end = this.bloodSugarTime.length-1
      this.bloodSugar.setOption({
        title: {
          text: ' {img|          血糖}',
          show: true,
          left: 20,
          textStyle: {
            color: '#8DD0FF',
            rich: {
              img: {
                color: '#8DD0FF',
                fontSize: '14',
                height: 40,
                width: 40,
                backgroundColor: {
                  image: require('../../../assets/images/xueya3.png')
                }
              },
            }
          }
        },
        legend: {
          top: 30,
          right: 30,
          itemWidth: 25,
          itemHeight: 2,
          itemGap: 10, //间距
          icon: 'rect',//图标样式
          textStyle: {
            //图例文字的样式
            color: "#8DD0FF",
            fontSize: 12
          },
          data: ['非空腹血糖','空腹血糖']
        },
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: "70",
          left: "30",
          right: "20",
          bottom: "50",
          containLabel: true,
          backgroundColor: '#333'
        },
        xAxis: {
          name: "",
          type: "category",
          boundaryGap: false,
          data: this.bloodSugarTime,
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#116BB7",
              width: "2"
            }
          },
          //网格线
          splitLine: {
            show: false,
            lineStyle: {
              width: "1",
              color: "rgba(255, 255, 255, 0.1)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            // interval: 0,
            textStyle: {
              color: "#8DD0FF",
              fontSize: 12
            },
            // formatter:function(value){  
            //   let name = value
            //   if (value.length>4){
            //     name = value.substring(0,4)+'...'
            //   }
            //   return name;
            // }
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: start,
            endValue: end,
            height: '10',
          },
          {
            type: 'slider'
          }
        ],
        yAxis: {
          name: "",
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#116BB7",
              width: "1"
            }
          },
          //网格线
          splitLine: {
            show: true,
            lineStyle: {
              width: "1",
              color: "rgba(17, 107, 183, 0.4)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            textStyle: {
              align: "right",
              baseline: "middle",
              color: "#8DD0FF",
              fontSize: 12
            }
          }
        },
        series: [
          {
            name: '空腹血糖',
            data: this.bloodSugarBot,
            barWidth: 16,
            color: '#644AFF',
            large: true,
            showSymbol: false,
            smooth: true,
            type: 'line',
            areaStyle: {normal: {}},
            itemStyle: {  
              normal: {   //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                      offset: 0, color: '#644AFF' // 0% 处的颜色
                  }, {
                      offset: 1, color: 'rgba(14, 206, 253, 0)' // 100% 处的颜色
                  }]
                )
              }  
            }
          },
          {
            name: '非空腹血糖',
            data: this.bloodSugarTop,
            barWidth: 16,
            color: '#04CDFF',
            large: true,
            showSymbol: false,
            smooth: true, 
            type: 'line',
            areaStyle: {normal: {}},
            itemStyle: {  
              normal: {   //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                      offset: 0, color: '#04CDFF' // 0% 处的颜色
                  }, {
                      offset: 1, color: 'rgba(14, 206, 253, 0)' // 100% 处的颜色
                  }]
                )
              }  
            }
          },
          
        ]
        // this.plateOfData.yData.map((item,index)=>{
        //   let color = ["#20DBEE","#42E1A6",'#3271FF']
        //   // let num = [10,15,20]
        //   return {...item,
        //   ...{
        //     type: "line", 
        //     smooth: true, 
        //     showSymbol: false, 
        //     seriesLayoutBy: "row", 
        //     barWidth: 8, 
        //     color: color[index],
        //     markLine : {
        //         symbol:"none",               //去掉警戒线最后面的箭头
        //         data : [{
        //             silent:false,             //鼠标悬停事件  true没有，false有
        //             label:{
        //               position:"start",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
        //               formatter: ""
        //             },
        //             lineStyle:{               //警戒线的样式  ，虚实  颜色
        //                 type:"dashed",
        //                 color:color[index]
        //             },
        //             yAxis: this.plateCordon[index].value
        //         }]
        //       }
        //     }
        //   }
        // })

      });
    },
    setEcharts(){
      this.bloodSugar.resize();
    }
  },
  mounted(){
    this.initEcharts()
    window.addEventListener('resize', () => this.setEcharts() ,false)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.setEcharts() , false)
  }
}
</script>

<style lang="less" scoped>
.bloodPressure{
  height: 3.85rem;
  width: 100%;
  margin-top: 0.3rem;
}
</style>