<template>
  <div class="humanBody">
    <div class="body" :class="showType? 'loadingShow': ''">
      <!-- 心率异常 -->
      <heart-rate v-show="wrongForm.心率 == true" />
      <!-- 体温偏高 -->
      <temperatureHigh v-show="wrongForm.体温 == true" />
      <!-- 血糖偏高 -->
      <bloodSugar v-show="wrongForm.血糖 == true" />
      <!-- 血氧偏高 -->
      <bloodOxygen v-show="wrongForm.血氧 == true" />
      <!-- 尿酸偏高 -->
      <uricAcid v-show="wrongForm.尿酸 == true" />
      <!-- 心电 -->
      <ecg v-show="form.心电" :form="form" />
      <!-- 总胆固醇偏高 -->
      <totalCholesterol v-show="wrongForm.总胆固醇 == true" />

      <div v-show="showType">
        <img src="../../../assets/images/loading.png" class="loading" alt="">
        <img src="../../../assets/images/loading1.png" class="loading1" alt="">
        <img src="../../../assets/images/loading2.png" class="ball" alt="">
        <div class="loadingText">正在测量中 请稍后</div>
      </div>
    </div>
  </div>
</template>

<script>
import heartRate from './wrong/heartRate'
import temperatureHigh from './wrong/temperatureHigh.vue'
import bloodSugar from './wrong/bloodSugar.vue'
import bloodOxygen from './wrong/bloodOxygen.vue'
import uricAcid from './wrong/uricAcid.vue'
import totalCholesterol from './wrong/totalCholesterol.vue'
import ecg from './wrong/ecg.vue'
export default {
  components: {
    heartRate,
    temperatureHigh,
    bloodSugar,
    bloodOxygen,
    uricAcid,
    totalCholesterol,
    ecg
  },
  props: {
    wrongForm: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    showType: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.humanBody{
  width: 5.54rem;
  height: 6.12rem;
  background-image: url(../../../assets/images/container_2.png);
  background-size: 100% 100%;
  .body{
    width: 4.66rem;
    height: 5.56rem;
    
    margin: 0.4rem auto 0;
    position: relative;
    font-size: 0.14rem;
    color: #F7C974;
    &::after{
      content:"";
      background-image: url(../../../assets/images/x.png);
      background-size: 100% 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 1;
    }
    .loading{
      position: absolute;
      left: -0.4rem;
      bottom: -0.67rem;
      width: 5.2rem;
    }
    .loading1{
      position: absolute;
      left: 1.15rem;
      bottom: 0.3rem;
      width: 2.4rem;
      z-index: 11;
      animation: animball 4s infinite;
    }
    .ball{
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      z-index: 12;
      bottom: 0.25rem;
      left: 0.15rem;
      animation: 
        animX 2s  cubic-bezier(0.36, 0, 0.64, 1) -1s infinite alternate,
        animY 2s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate
    }
    .loadingText{
      color: #74c6ff;
      font-size: 0.22rem;
      position: absolute;
      top: 2.4rem;
      width: 2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 12;
      animation: animtext 4s infinite;
    }
  }
  .loadingShow{
    &::after{
      opacity: 0.4;
    }
  }
}
@keyframes animtext {
  0%{opacity: 1;}
  25%{opacity: 0.2;}
  50%{opacity: 1;}
  65%{opacity: 0.2;}
  75%{opacity: 1;}
  90%{opacity: 0.2;}
  100%{opacity: 1;}
}
@keyframes animball {
  0%{opacity: 0.2;}
  25%{opacity: 0.5;}
  50%{opacity: 0.5;}
  75%{opacity: 1;}
  100%{opacity: 0.2;}
}
@keyframes animX{
      0% {left: 0.12rem;}
    100% {left: 3.9rem;}
}
@keyframes animY{
    0% {bottom: -0.2rem}
    100% {bottom:  0.6rem}

}
</style>