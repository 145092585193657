<template>
  <div class="basicInfo">
    <wTitle title="个人基本信息" />
    <div class="content">
      <div class="photo">
        <img src="../../../assets/images/tx.png" alt="">
      </div>
      <div class="info">
        <div class="oneself">
          <span class="name">{{userInfo.jbxx_xm}}</span>
          <span class="sex">{{userInfo.jbxx_xb ? userInfo.jbxx_xb == 2 ? '女' : '男' : '-'}}</span>
          <span>{{userInfo.age ? userInfo.age : '-'}}岁</span>
        </div>
        <div class="other">
          <div class="left">
            <div>联系人姓名：{{userInfo.jbxx_lxrxm}}</div>
            <div>健康等级：{{userInfo.health_level}}</div>
            <div>高血压：{{userInfo.gxy_bz == 1 ? '有' : '无'}}</div>
            <div>签约机构：{{doctor_team.doctor_company}}</div>
          </div>
          <img class="line" src="../../../assets/images/line.png" alt="">
          <div class="right">
            <div>联系人电话：{{userInfo.jbxx_lxrdh}}</div>
            <div>追踪状态：{{userInfo.abnormal == 1
                    ? '追踪正常'
                    : userInfo.abnormal == 2
                    ? '追踪异常'
                    : '未追踪'}}</div>
            <div>家庭医生：{{doctor_team.doctor_name}}</div>
            <div>健康管理员：{{doctor_team.health_manager_name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wTitle from './wTitle.vue'
export default {
  components: {
    wTitle
  },
  props: {
    userInfo:{
      type: Object,
      default: ()=>{}
    },
    doctor_team:{
      type: Object,
      default: ()=>{}
    }
  }
}
</script>

<style lang="less" scoped>
.basicInfo{
  width: 6.23rem;
  height: 3rem;
  border: 2px solid;
  border-image: linear-gradient(180deg, rgba(47, 123, 204, 1), rgba(90, 140, 198, 1), rgba(96, 143, 200, 1), rgba(33, 105, 182, 1)) 2 2;
  box-shadow: 0px 0px 20px 0px #508896 inset;
  text-align: left;
  .content{
    margin: 0.4rem 0.27rem 0;
    display: flex;
    .photo{
      width: 1.08rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid rgba(44, 144, 255, 0.5);
      margin-right: 0.27rem;
      position: relative;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    .info{
      flex: 1;
      .oneself{
        height: 0.32rem;
        line-height: 0.32rem;
        font-size: 0.16rem;
        .name{
          font-size: 0.22rem;
          font-weight: bold;
        }
        .sex{
          margin: 0 0.08rem 0 0.16rem;
        }
      }
      
      .other{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.14rem;
        color: #8DD0FF;
        margin-top: 0.12rem;
        .line{
          width: 0.01rem;
          height: 1rem;
          margin-right: 0.45rem;
        }
        .left{
          width: 1.88rem;
          line-height: 0.28rem;
        }
        .right{
          flex: 1;
          line-height: 0.28rem;
        }
      }
    }
  }
}
</style>