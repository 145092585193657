<template>
  <div class="MBI" id="MBI"></div>
</template>

<script>
export default {
  data(){
    return {
      bmi: null
    }
  },
  methods:{
    initEcharts(){
       this.bmi = this.$echarts.init(
        document.getElementById("MBI")
      );
      this.bmi.setOption({
        title: {
          text: ' {img|          BMI}',
          show: true,
          left: 10,
          top: 10,
          textStyle: {
            color: '#8DD0FF',
            rich: {
              img: {
                color: '#8DD0FF',
                fontSize: '14',
                height: 42,
                width: 42,
                backgroundColor: {
                  image: require('../../../assets/images/xueya2.png')
                }
              },
            }
          }
        },
        legend: {
          top: 30,
          right: 30,
          
          textStyle: {
            //图例文字的样式
            color: "#8DD0FF",
            fontSize: 12
          },
          data: ['增速(100%)']
        },
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: "80",
          left: "30",
          right: "20",
          bottom: "15",
          containLabel: true,
          backgroundColor: '#333'
        },
        xAxis: {
          name: "",
          type: "category",
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#116BB7",
              width: "2"
            }
          },
          //网格线
          splitLine: {
            show: false,
            lineStyle: {
              width: "1",
              color: "rgba(255, 255, 255, 0.1)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#8DD0FF",
              fontSize: 12
            },
            // formatter:function(value){  
            //   let name = value
            //   if (value.length>4){
            //     name = value.substring(0,4)+'...'
            //   }
            //   return name;
            // }
          }
        },
        yAxis: {
          name: "",
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#116BB7",
              width: "1"
            }
          },
          //网格线
          splitLine: {
            show: true,
            lineStyle: {
              width: "1",
              color: "rgba(17, 107, 183, 0.4)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            textStyle: {
              align: "right",
              baseline: "middle",
              color: "#8DD0FF",
              fontSize: 12
            }
          }
        },
        series: [
          {
            name: '增速(100%)',
            data: [150, 230, 224, 218, 135, 147, 260],
            barWidth: 16,
            color: '#04CDFF',
            // showSymbol: false,
            // smooth: true,
            type: 'line',
            stack: 'Total',
            areaStyle: {normal: {}},
            itemStyle: {  
              normal: {   //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                      offset: 0, color: '#04CDFF' // 0% 处的颜色
                  }, {
                      offset: 1, color: 'rgba(14, 206, 253, 0)' // 100% 处的颜色
                  }]
                )
              }  
            }
          }
          
        ]
        // this.plateOfData.yData.map((item,index)=>{
        //   let color = ["#20DBEE","#42E1A6",'#3271FF']
        //   // let num = [10,15,20]
        //   return {...item,
        //   ...{
        //     type: "line", 
        //     smooth: true, 
        //     showSymbol: false, 
        //     seriesLayoutBy: "row", 
        //     barWidth: 8, 
        //     color: color[index],
        //     markLine : {
        //         symbol:"none",               //去掉警戒线最后面的箭头
        //         data : [{
        //             silent:false,             //鼠标悬停事件  true没有，false有
        //             label:{
        //               position:"start",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
        //               formatter: ""
        //             },
        //             lineStyle:{               //警戒线的样式  ，虚实  颜色
        //                 type:"dashed",
        //                 color:color[index]
        //             },
        //             yAxis: this.plateCordon[index].value
        //         }]
        //       }
        //     }
        //   }
        // })

      });
    },
    setEcharts(){
      this.bmi.resize();
    }
  },
  mounted(){
    this.initEcharts()
    window.addEventListener('resize', () => this.setEcharts() ,false)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.setEcharts() , false)
  }
}
</script>

<style lang="less" scoped>
.MBI{
  width: 5.44rem;
  height: 3rem;
  margin-top: 0.16rem;
  border: 2px solid;
  border-image: linear-gradient(180deg, rgba(47, 123, 204, 1), rgba(90, 140, 198, 1), rgba(96, 143, 200, 1), rgba(33, 105, 182, 1)) 2 2;
}
</style>