<template>
  <div class="title" @click="titClick">
    <span>{{title}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      list: [
        { id: 1, name: '张三', type: 1, status: 2 },
        { id: 1, name: '张三四', type: 1, status: 2 },
        { id: 1, name: '张三五', type: 2, status: 1 },
        { id: 1, name: '张三五六', type: 2, status: 1 },
        { id: 1, name: '张三五六七', type: 2, status: 2 },
      ],
      newList: [],
      form: {
        name: '四',
        type: 1,
        status: 2
      }
    }
  },
  methods: {
    titClick(){
      this.$emit('click')
    },
    changeTab(){ //点击搜索
      const l = this.list.filter(item=> {
        return this.isSearch(item)
      })
      console.log(l)
    },
    isSearch(item){
      let form = this.form
      for(let i in form){
        if(form[i] != '' && form[i] != ' '){ //判断搜索条件不为空
          if(i == 'name'){ //判断搜索条件是模糊匹配
            if(item[i].indexOf(form[i]) == -1){
              return false
            }
          } else { //精确匹配
            if(form[i] != item[i]){
              return false
            }
          }
        }
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.title{
  width: 7.29rem;
  height: 0.73rem;
  position: absolute;
  top: 0;
  left: 6.27rem;
  color: #fff;
  font-size: 0.26rem;
  line-height: 0.7rem;
}
</style>