<template>
  <div class="heartRate">
    <div class="heart">
      <div class="box"></div>
      <div>心电：{{form.心电}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    },
  }
}
</script>

<style lang="less" scoped>
.heartRate{
  width: 1.29rem;
  height: 0.26rem;
  position: absolute;
  top: 1.78rem;
  left: 3.43rem;
  .heart{
    width: 100%;
    height: 100%;
    border: 0.01rem solid #F7C974;
    display: flex;
    align-items: center;
    .box{
      width: 0.14rem;
      height: 0.14rem;
      background-color: #00b400;
      margin: 0 0.08rem;
    }
  }
}
</style>