<template>
  <div class="heartRate">
    <div class="heart">
      <div class="box"></div>
      <div>尿酸偏高</div>
    </div>
    <w-line-2 class="wline" />
    <div class="spot"></div>
  </div>
</template>

<script>
import wLine2 from './wLine2.vue'
export default {
  components: {
    wLine2
  }
}
</script>

<style lang="less" scoped>
.heartRate{
  width: 1rem;
  height: 0.26rem;
  position: absolute;
  top: 2.58rem;
  left: 0.21rem;
  .heart{
    width: 100%;
    height: 100%;
    border: 0.01rem solid #F7C974;
    display: flex;
    align-items: center;
    .box{
      width: 0.14rem;
      height: 0.14rem;
      background-color: #E61B7B;
      margin: 0 0.08rem;
    }
  }
  .wline{
    position: absolute;
    left: 1.1rem;
    top: -0.13rem;
  }
  .spot{
    width: 0.18rem;
    height: 0.18rem;
    background: #E61B7B;
    opacity: 0.5;
    filter: blur(2px);
    position: absolute;
    top: -0.22rem;
    left: 2rem;
  }
}
</style>