<template>
  <div
    class="wTitle"
    :style="{
      marginTop: top * 0.01 +'rem',
      marginLeft: left  * 0.01 +'rem'
    }"
  >
    <span>{{title}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    top: {
      type: Number,
      default: 37
    },
    left: {
      type: Number,
      default: 27
    }
  }
}
</script>

<style lang="less" scoped>
.wTitle{
  width: 3.43rem;
  height: 0.22rem;
  line-height: 0.17rem;
  background-image: url(../../../assets/images/tt.png);
  background-size: 100% 100%;
  color: #F7C974;
  font-size: 0.2rem;
  text-align: left;
  text-indent: 0.8rem;
}
</style>