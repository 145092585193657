<template>
  <div class="bloodPressure" id="bloodPressure"></div>
</template>

<script>
export default {
  data(){
    return {
      bloodPressureTop: [],
      bloodPressureBot: [],
      bloodPressureTime: [],
      bloodPressure: null
    }
  },
  methods:{
    initData(data){
      console.log(data)
      this.bloodPressureTop = data.top
      this.bloodPressureBot = data.bot
      this.bloodPressureTime = data.time
      this.initEcharts()
    },
    initEcharts(){
       this.bloodPressure = this.$echarts.init(
        document.getElementById("bloodPressure")
      );
      var start = this.bloodPressureTime.length - 6
      var end = this.bloodPressureTime.length-1
      this.bloodPressure.setOption({
        title: {
          text: ' {img|          血压}',
          show: true,
          left: 20,
          textStyle: {
            color: '#8DD0FF',
            rich: {
              img: {
                color: '#8DD0FF',
                fontSize: '14',
                height: 40,
                width: 40,
                backgroundColor: {
                  image: require('../../../assets/images/xueya.png')
                }
              },
            }
          }
        },
        legend: {
          top: 30,
          right: 30,
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 10, //间距
          icon: 'rect',//图标样式
          textStyle: {
            //图例文字的样式
            color: "#8DD0FF",
            fontSize: 12
          },
          data: ['舒张压','收缩压']
        },
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: "70",
          left: "30",
          right: "20",
          bottom: "50",
          containLabel: true,
          backgroundColor: '#333'
        },
        xAxis: {
          name: "",
          type: "category",
          data: this.bloodPressureTime,
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#116BB7",
              width: "2"
            }
          },
          //网格线
          splitLine: {
            show: false,
            lineStyle: {
              width: "1",
              color: "rgba(255, 255, 255, 0.1)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            // interval: 0,
            textStyle: {
              color: "#8DD0FF",
              fontSize: 12
            },
            // formatter:function(value){  
            //   let name = value
            //   if (value.length>4){
            //     name = value.substring(0,4)+'...'
            //   }
            //   return name;
            // }
          },
          splitArea:{
            show: true,
            areaStyle: {
              color: ['rgba(95, 180, 255, 0.09)','rgba(95, 180, 255, 0.09)']
            }
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: start,
            endValue: end,
            height: '10',
          },
          {
            type: 'slider'
          }
        ],
        yAxis: {
          name: "",
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#116BB7",
              width: "1"
            }
          },
          //网格线
          splitLine: {
            show: true,
            lineStyle: {
              width: "1",
              color: "rgba(17, 107, 183, 0.4)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            textStyle: {
              align: "right",
              baseline: "middle",
              color: "#8DD0FF",
              fontSize: 12
            }
          }
        },
        series: [
          {
            name: '舒张压',
            data: this.bloodPressureBot,
            barWidth: 16,
            color: '#04CDFF',
            type: 'bar',
            large: true,
            label: {
              show: true,
              position: 'top',
              color: '#04CDFF',
              fontSize: 12
            },
          },
          {
            name: '收缩压',
            data: this.bloodPressureTop,
            barWidth: 16,
            color: '#644AFF',
            type: 'bar',
            large: true,
            label: {
              show: true,
              position: 'top',
              color: '#644AFF',
              fontSize: 12
            },
          },
          // {
          //   type: 'line',
          //   markLine:{
          //     symbol:"none",               //去掉警戒线最后面的箭头
          //       data : [{
          //         silent:false,             //鼠标悬停事件  true没有，false有
          //         name: '下',
          //         label:{
          //           position:"middle",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
          //           formatter: '{b}',
          //           color:'#04CDFF'
          //         },
          //         lineStyle:{               //警戒线的样式  ，虚实  颜色
          //             type:"dashed",
          //             color:'#66ccff'
          //         },
          //         yAxis: 100
          //     }]
          //   }
          // },
          // {
          //   type: 'line',
          //   markLine:{
          //     symbol:"none",               //去掉警戒线最后面的箭头
          //       data : [{
          //         silent:false,             //鼠标悬停事件  true没有，false有
          //         name: '上',
          //         label:{
          //           position:"middle",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
          //           formatter: '{b}',
          //           color:'#644AFF'
          //         },
          //         lineStyle:{               //警戒线的样式  ，虚实  颜色
          //             type:"dashed",
          //             color:'#644AFF'
          //         },
          //         yAxis: 130
          //     }]
          //   }
          // },
        
        ]
        // this.plateOfData.yData.map((item,index)=>{
        //   let color = ["#20DBEE","#42E1A6",'#3271FF']
        //   // let num = [10,15,20]
        //   return {...item,
        //   ...{
        //     type: "line", 
        //     smooth: true, 
        //     showSymbol: false, 
        //     seriesLayoutBy: "row", 
        //     barWidth: 8, 
        //     color: color[index],
        //     markLine : {
        //         symbol:"none",               //去掉警戒线最后面的箭头
        //         data : [{
        //             silent:false,             //鼠标悬停事件  true没有，false有
        //             label:{
        //               position:"start",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
        //               formatter: ""
        //             },
        //             lineStyle:{               //警戒线的样式  ，虚实  颜色
        //                 type:"dashed",
        //                 color:color[index]
        //             },
        //             yAxis: this.plateCordon[index].value
        //         }]
        //       }
        //     }
        //   }
        // })

      });
    },
    setEcharts(){
      this.bloodPressure.resize();
    }
  },
  mounted(){
    this.initEcharts()
    window.addEventListener('resize', () => this.setEcharts() ,false)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.setEcharts() , false)
  }
}
</script>

<style lang="less" scoped>
.bloodPressure{
  height: 3.85rem;
  width: 100%;
  margin-top: 0.3rem;
}
</style>