<template>
  <div class="heartRate">
    <div class="heart">
      <div class="box"></div>
      <div>心率异常</div>
    </div>
    <w-line class="wline" />
    <div class="spot"></div>
  </div>
</template>

<script>
import wLine from './wLine.vue'
export default {
  components: {
    wLine
  }
}
</script>

<style lang="less" scoped>
.heartRate{
  width: 1rem;
  height: 0.26rem;
  position: absolute;
  top: 1.42rem;
  left: 3.43rem;
  .heart{
    width: 100%;
    height: 100%;
    border: 0.01rem solid #F7C974;
    display: flex;
    align-items: center;
    .box{
      width: 0.14rem;
      height: 0.14rem;
      background-color: #E61B7B;
      margin: 0 0.08rem;
    }
  }
  .wline{
    position: absolute;
    left: -0.8rem;
    top: -0.13rem;
  }
  .spot{
    width: 0.18rem;
    height: 0.18rem;
    background: #E61B7B;
    opacity: 0.5;
    filter: blur(2px);
    position: absolute;
    top: -0.22rem;
    left: -0.9rem;
  }
}
</style>