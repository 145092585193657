<template>
  <div class="textData">
    <wTitle title="检测数据" :top="48" />
    <div class="content">
      <div class="eachdata"
        v-for="(item,index) in list"
        :key="index"
      >
        <div class="once" :class="item.status == 'test' || item.status == 'normal' ? 'wongTest' : item.status ? 'wrongOnce' : ''">
          <span>{{item.data}}</span><span>{{item.unit}}</span>
        </div>
        <div class="tit" :class="item.status == 'test' ? 'wongTitTest' : item.status ? 'wrongtit' : ''">{{item.zh}}</div>
      </div>

    </div>
  </div>
</template>

<script>
import wTitle from './wTitle.vue'
export default {
  components: {
    wTitle
  },
  props:{
    form: {
      type: Object,
      default: () => {}
    },
    wrongForm: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    form: {
      handler: function(){
        let arr = this.form
        for(let i in arr){
          let index = this.list.findIndex(item=>item.type == i)
          if(index != -1){
            this.list[index].data = arr[i] ? arr[i] : '-'
          }
        }
      },
      deep: true,
      immediate: true
    },
    wrongForm: {
      handler: function(){
        let arr = this.wrongForm
        console.log(arr,'arr')
        for(let i in arr){
          let index = this.list.findIndex(item=>item.type == i)
          if(index != -1){
            this.list[index].status = arr[i]
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  
  data(){
    return {
      list: [
        {type:'身高',zh: '身高', unit: 'cm', status: false, data: '-'},
        {type:'体重',zh: '体重', unit: 'kg', status: false, data: '-'},
        {type:'体温',zh: '体温', unit: '℃', status: false, data: '-'},
        {type:'腰围',zh: '腰围', unit: 'cm', status: false, data: '-'},
        {type:'血压',zh: '收缩压/舒张压', unit: 'mmHg', status: false, data: '-'},
        {type:'心率',zh: '心率', unit: '次/分', status: false, data: '-'},
        {type:'血糖',zh: '血糖', unit: 'mmol/L', status: false, data: '-'},
        {type:'血氧',zh: '血氧', unit: 'HbO2', status: false, data: '-'},
        {type:'血脂',zh: '血脂', unit: 'mmol/L', status: false, data: '-'},
        {type:'尿酸',zh: '尿酸', unit: 'μmol/L', status: false, data: '-'},
        {type:'总胆固醇',zh: '胆固醇', unit: 'mmol/L', status: false, data: '-'},
        {type:'BMI',zh: 'BMI', unit: 'bmi', status: false, data: '-'},
      ]
    }
  },
  methods: {
    reset(){
      this.list = [
        {type:'身高',zh: '身高', unit: 'cm', status: false, data: '-'},
        {type:'体重',zh: '体重', unit: 'kg', status: false, data: '-'},
        {type:'体温',zh: '体温', unit: '℃', status: false, data: '-'},
        {type:'腰围',zh: '腰围', unit: 'cm', status: false, data: '-'},
        {type:'血压',zh: '收缩压/舒张压', unit: 'mmHg', status: false, data: '-'},
        {type:'心率',zh: '心率', unit: '次/分', status: false, data: '-'},
        {type:'血糖',zh: '血糖', unit: 'mmol/L', status: false, data: '-'},
        {type:'血氧',zh: '血氧', unit: 'HbO2', status: false, data: '-'},
        {type:'血脂',zh: '血脂', unit: 'mmol/L', status: false, data: '-'},
        {type:'尿酸',zh: '尿酸', unit: 'μmol/L', status: false, data: '-'},
        {type:'总胆固醇',zh: '胆固醇', unit: 'mmol/L', status: false, data: '-'},
        {type:'BMI',zh: 'BMI', unit: 'bmi', status: false, data: '-'},
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.textData{
  width: 6.23rem;
  height: 6.08rem;
  background-image: url(../../../assets/images/container_1.png);
  background-size: 100% 100%;
  margin-top: 0.18rem;
  display: flex;
  flex-direction: column;
  .content{
    margin: 0.34rem 0.2rem 0;
    display: flex;
    flex-wrap: wrap;
    .eachdata{
      margin: 0 0.17rem;
      &:nth-child(even){
        margin-top: 0.2rem;
      }
      .once{
        width: 1.1rem;
        height: 1.1rem;
        
        background-image: url(../../../assets/images/container_s_1.png);
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span{
          &:nth-child(1){
            line-height: 0.45rem;
            font-size: 0.24rem;
          }
          &:nth-child(2){
            line-height: 0.2rem;
            font-size: 0.16rem;
            color: #8DD0FF;
          }
        }
      }
      .wongTest{
        background-image: url(../../../assets/images/container_s_1_test.png);
        color: #fff;
        span{
          &:nth-child(2){
            color: #8DD0FF;
          }
        }
      }
      .wrongOnce{
        background-image: url(../../../assets/images/container_s_1_warning.png);
        color: #F7C974;
        span{
          &:nth-child(2){
            color: #F7C974;
          }
        }
      }
      .tit{
        height: 0.3rem;
        line-height: 0.3rem;
        font-size: 0.16rem;
      }
      .wongTitTest{
        color: #fff;
      }
      .wrongtit{
        color: #F7C974;
      }
    }
    
  }
}
</style>