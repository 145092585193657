<template>
  <div class="screenContent">
    <div class="loading" v-if="loading">
      <img src="@/assets/images/loading-bg.png" alt="" />
      <div class="loading-t1">欢迎使用</div>
      <div class="loading-t2">请稍候</div>
    </div>
    <div id="screen" v-if="!loading">
      <screen-title :title="title" @click="screenfullClick"/>

      <div class="screen-left">
        <!-- 基本信息 -->
        <basic-information :userInfo="userInfo" :doctor_team="doctor_team"/>
        <!-- 检测数据 -->
        <test-data ref="textData" :form="form" :wrongForm="wrongForm" />
      </div>

      <div class="screen-center">
        <!-- 人体 -->
        <human-body :wrongForm="wrongForm" :form="form" :showType="bodyShowType"/>
        <!-- BMI -->
        <BMI />
      </div>
      
      <div class="screen-right">
        <w-title title="历史数据" :top="48" />
        <!-- 血压 -->
        <blood-pressure ref="bloodPressure" />
        <!-- 血糖 -->
        <blood-sugar ref="bloodSugar" />
      </div>

    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import wTitle from './components/wTitle.vue'
import screenTitle from './components/screenTitle.vue'
import basicInformation from './components/basicInformation.vue'
import testData from './components/testData.vue'
import bloodPressure from './components/bloodPressure.vue'
import bloodSugar from './components/bloodSugar.vue'
import humanBody from './components/humanBody.vue'
import BMI from './components/BMI.vue'
import { getListInfo } from "@/api/userInfo.js";
import { mapState } from 'vuex'

import mqtt from "mqtt";

export default {
  components: {
    screenTitle,
    basicInformation,
    testData,
    wTitle,
    bloodPressure,
    bloodSugar,
    humanBody,
    BMI
  },
  data(){
    return {
      title: '迪安安吉健康舱',
      form: {
        身高: '-',
        体重: '-',
        体温: '-',
        腰围: '-',
        血压: '-',
        心率: '-',
        血糖: '-',
        BMI: '-',
        血氧: '-',
        血脂: '-',
        尿酸: '-',
        总胆固醇: '-'
      },
      wrongForm: {
        身高: false,
        体重: false,
        体温: false,
        腰围: false,
        血压: false,
        心率: false,
        血糖: false,
        BMI: false,
        血氧: false,
        血脂: false,
        尿酸: false,
        总胆固醇: false
      },
      // sfzh: "330523200207212815",
      sfzh: "",
      doctor_team: {},
      userInfo: {},
      bloodPressureTop: [],
      bloodPressureBot: [],
      bloodPressureTime: [],

      bloodSugarTop: [],
      bloodSugarBot: [],
      bloodSugarTime: [],

      msg: "--",
      loading: true,
      muted: true,
      isFullscreen: false,
      bodyShowType: false
    }
  },
  computed: {
    ...mapState([
      'healthHouseDeviceName'
    ])
  },
  methods: {
    chnageSound(status){
      this.muted = status
    },
    getListInfo() {
      getListInfo({
        sfzh: this.sfzh,
      }).then((res) => {
        console.log(res.data);
        this.doctor_team = res.data.doctor_team;
        this.userInfo = res.data.jbxx;
        const vitalsign_blood_pressure = res.data.vitalsign_blood_pressure;
        const vitalsign_blood_sugar = res.data.vitalsign_blood_sugar;
        this.bloodPressureTop = [];
        this.bloodPressureBot = [];
        this.bloodPressureTime = [];
        vitalsign_blood_pressure.map((item) => {
          if (item.name == "高压") {
            this.bloodPressureTop.push(item.value);
            this.bloodPressureTime.push(item.day);
          } else {
            this.bloodPressureBot.push(item.value);
          }
        });

        this.bloodSugarTop = [];
        this.bloodSugarBot = [];
        this.bloodSugarTime = [];
        vitalsign_blood_sugar.map((item) => {
          if (item.name == "非空腹血糖") {
            this.bloodSugarTop.push(item.value);
            this.bloodSugarTime.push(item.day);
          } else {
            this.bloodSugarBot.push(item.value);
          }
        });

        this.$nextTick(() => {
          this.$refs.bloodPressure.initData({
            top: this.bloodPressureTop,
            bot: this.bloodPressureBot,
            time: this.bloodPressureTime,
          });

          this.$refs.bloodSugar.initData({
            top: this.bloodSugarTop,
            bot: this.bloodSugarBot,
            time: this.bloodSugarTime,
          });
        });
      });
    },
    reset() {
      this.form = {
        身高: '-',
        体重: '-',
        体温: '-',
        腰围: '-',
        血压: '-',
        心率: '-',
        血糖: '-',
        BMI: '-',
        血氧: '-',
        血脂: '-',
        尿酸: '-',
        总胆固醇: '-'
      };
      this.wrongForm = {
        身高: false,
        体重: false,
        体温: false,
        腰围: false,
        血压: false,
        心率: false,
        血糖: false,
        BMI: false,
        血氧: false,
        血脂: false,
        尿酸: false,
        总胆固醇: false
      };
      this.doctor_team = {};
      this.userInfo = {};
      this.$nextTick(()=>{
        // this.$refs.textData.reset()
      })
    },
    sendMse() {
      client.publish(
        "hh-origin",
        JSON.stringify({
          sfzh: "330523194907016227",
          action: "start",
        }),
        { qos: 0, retain: false },
        (error2) => {
          console.log("发送消息", error2);
          if (error2) {
            console.log("Publish error", error2);
          }
        }
      );
    },
    sendMse2() {
      client.publish(
        "hh-origin",
        JSON.stringify({
          sfzh: "330523194907016227",
          action: "end",
        }),
        { qos: 0, retain: false },
        (error2) => {
          console.log("发送消息", error2);
          if (error2) {
            console.log("Publish error", error2);
          }
        }
      );
    },
    mqttMsg() {
      var client;
      const options = {
        connectTimeout: 40000,
        clean: true,
        name: "fd",
        clientId: null,
        username: "admin",
        password: "admin123",
      };
      client = mqtt.connect("ws://106.15.62.8:8083/mqtt", options);


      let that = this;
      console.log('mqttMsg',client,that.healthHouseDeviceName)
      client.on("connect", (e) => {
        console.log(e,'lianjie')
        console.log("连接成功！！！");

        client.subscribe(that.healthHouseDeviceName, (error) => { //hh-origin
          if (!error) {
            console.log("订阅成功-" + that.healthHouseDeviceName);
          } else {
            console.log("订阅失败-" + that.healthHouseDeviceName);
          }
        });
        // client.subscribe("hh-devicedata", (error) => {
        //   if (!error) {
        //     console.log("订阅成功-hh-devicedata");
        //   } else {
        //     console.log("订阅失败-hh-devicedat");
        //   }
        // });
      },err=>{
        console.log(err,'err')
      });
      // 接收消息处理
      client.on("message", (topic, message) => {
        console.log("收到来自", topic, "的消息", message.toString());

        try {
          const params = JSON.parse(message.toString());
          console.log(params);
          if(topic == that.healthHouseDeviceName){
            if(params.action == 'DeviceData'){
              if(params.血压) that.handlingExceptions('血压',params.血压)
              if(params.心率) that.handlingExceptions('心率',params.心率)
              if(params.体温) that.handlingExceptions('体温',params.体温)
              if(params.血糖) that.handlingExceptions('血糖',params.血糖)
              if(params.血脂) that.handlingExceptions('血脂',params.血脂)
              if(params.尿酸) that.handlingExceptions('尿酸',params.尿酸)
              if(params.血氧) that.handlingExceptions('血氧',params.血氧)
              if(params.总胆固醇) that.handlingExceptions('总胆固醇',params.总胆固醇)
              if(params.心电) that.handlingExceptions('心电',params.心电)
              if(params.体脂率) that.handlingExceptions('体脂率',params.体脂率)
              if(params.腰围) that.handlingExceptions('腰围',params.腰围)
              if(params.BMI) that.handlingExceptions('BMI',params.BMI)
              if(params.身高) that.handlingExceptions('身高',params.身高)
              if(params.体重) that.handlingExceptions('体重',params.体重)
              if(params.中医四诊) that.handlingExceptions('中医四诊',params.中医四诊)

              
              that.bodyShowType = false


              // if (params.blood_preasure_high)
              //   that.form.blood_preasure_high = params.blood_preasure_high;
              // if (params.blood_preasure_low)
              //   that.form.blood_preasure_low =
              //     params.blood_preasure_high + "/" + params.blood_preasure_low;
              // if (params.heart_rate) that.form.heart_rate = params.heart_rate;
              // if (params.blood_oxygen) that.form.blood_oxygen = params.blood_oxygen;
              // if (params.temp) that.form.temp = params.temp;
              // if (params.bmi) that.form.bmi = params.bmi;

              // if (params.waist) that.form.waist = params.waist;
              // if (params.height) that.form.height = params.height;
              // if (params.weight) that.form.weight = params.weight;
              // if (params.fatrate) that.form.fatrate = params.fatrate;
            }
            

            // if (topic == "hh-origin") {
              console.log(that.form);
              if (params.sfzh && params.action == "start") {
                that.reset();
                that.sfzh = params.sfzh;
                that.getListInfo();
                that.loading = false;
                that.bodyShowType = false;
                console.log("tupian关");
              }
              if (params.action == "end") {
                setTimeout(() => {
                  that.loading = true;
                  console.log("tupian开");
                }, 1500);
              }
              if(params.action == 'startMeasuring'){
                that.bodyShowType = true
                that.testMeasuring(params.measureItems)
              }
            // }
            // if(params.action == 'PlayAudioText'){
            //   that.playAudio(params.audioUrl)
            // }
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    handlingExceptions(name,str){
      let arr = str.split(',')
      this.form[name] = arr[0]
      if(arr.length == 1){
        this.wrongForm[name] = 'normal'
      } else {
        this.wrongForm[name] = true
      }
    },
    testMeasuring(str){
      let arr = str.split(',')
      arr.map(item=>{
        this.wrongForm[item] = 'test'
      })
    },
    playAudio(src){
      // var au = new Audio()
      // au.src = '../../assets/dududu.mp3'
      // au.play()
      // console.log(this.$refs.audio,src)
      // this.$refs.audio.src = require('../../assets/smile.mp3')
      if(src) this.$refs.audio.src = src
      this.$refs.audio.play().then(res=>{
        console.log('声音-success')
        this.muted = false
      }).catch(err=>{
        console.log('声音-fail--'+err)
        this.muted = true
      })
    },
    screenfullClick() {
      // this.handlingExceptions('血压','000/000,异常')
      if (!screenfull.enabled) {
        this.$message.info('you browser can not work')
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.enabled) {
        screenfull.on('change', this.change)
      }
    },
    destroy() {
      if (screenfull.enabled) {
        screenfull.off('change', this.change)
      }
    }
  },
  created() {
    // this.getListInfo()
    console.log(this.healthHouseDeviceName)
    if(this.healthHouseDeviceName){
      console.log('连接')

      this.mqttMsg();
    } else {
      this.$router.push('/login')
    }
    
  },
  mounted() {
    this.init()
    // this.getListInfo()
  },
  beforeDestroy() {
    this.destroy()
  },
}
</script>

<style lang="less" scoped>
.screenContent{
  width: 100%;
  height: 100%;
}
.loading {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .loading-t1{
    font-size: 0.89rem;
    color: #fff;
    width: 3.56rem;
    height: 1.24rem;
    line-height: 1.24rem;
    font-weight: 100;
    position: absolute;
    top: 3.89rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .loading-t2{
    font-size: 0.78rem;
    color: #fff;
    width: 2.34rem;
    height: 1.1rem;
    line-height: 1.1rem;
    font-weight: 100;
    position: absolute;
    top: 5.13rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
#screen{
  width: 19.2rem;
  height: 100%;
  background-image: url(../../assets/images/background.png);
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  .screen-left,.screen-center,.screen-right{
    margin-top: 1.1rem;
  }
  .screen-left{
    width: 6.23rem;
    margin-left: 0.56rem;
    // border: 1px solid #66ccff;
  }
  .screen-center{
    width: 5.54rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .screen-right{
    width: 5.81rem;
    height: 9.38rem;
    margin-right: 0.6rem;
    margin-top: 1rem;
    background-image: url(../../assets/images/container_3.png);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>