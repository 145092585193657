<template>
  <div class="heartRate">
    <div class="heart">
      <div class="box"></div>
      <div>血氧过低</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.heartRate{
  width: 1rem;
  height: 0.26rem;
  position: absolute;
  top: 2.18rem;
  left: 0.21rem;
  .heart{
    width: 100%;
    height: 100%;
    border: 0.01rem solid #F7C974;
    display: flex;
    align-items: center;
    .box{
      width: 0.14rem;
      height: 0.14rem;
      background-color: #E61B7B;
      margin: 0 0.08rem;
    }
  }
}
</style>